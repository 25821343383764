import React from 'react';
import cx from 'classnames';
import styles from './SubHeadline.module.scss';

const SubHeadline = ({ children }) => (
  <p className={cx('mb3', styles.padding)}>
    {children}
  </p>
);

export default SubHeadline;
