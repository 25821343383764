import React from 'react';

const AtomOneDark = () => (
  <div dangerouslySetInnerHTML={{
    __html: `
      <div class="hljs code-wrapper" style="box-sizing: border-box; position: relative; font-family: Consolas,'Liberation Mono',Menlo,Courier,monospace; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial; text-decoration-color: initial; display: block; overflow-x: auto; padding: 0.5em; color: #abb2bf; background: #282c34; white-space: pre-wrap; word-break: break-all; word-wrap: break-word; tab-size: 4;"><pre class="hljs pre-wrapper" style="border-radius: 3px; box-sizing: border-box; font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace; font-size: 13.6px; margin-bottom: 0px; margin-top: 0px; overflow: auto; position: relative; word-break: normal; word-wrap: normal; display: block; overflow-x: auto; padding: 0.5em; color: #abb2bf; background: #282c34; tab-size: 4; font-size: 14px;"><span class="hljs-function" style="box-sizing: border-box; tab-size: 4;"><span class="hljs-keyword" style="box-sizing: border-box; color: #c678dd; tab-size: 4;">function</span> <span class="hljs-title" style="box-sizing: border-box; color: #61aeee; tab-size: 4;">ex</span><span class="hljs-params" style="box-sizing: border-box; tab-size: 4;">(i)</span> {</span>
  <span class="hljs-keyword" style="box-sizing: border-box; color: #c678dd; tab-size: 4;">if</span> (<span class="hljs-built_in" style="box-sizing: border-box; color: #e6c07b; tab-size: 4;">i</span> &lt; <span class="hljs-number" style="box-sizing: border-box; color: #d19a66; tab-size: 4;">2</span>) {
    console.<span class="hljs-built_in" style="box-sizing: border-box; color: #e6c07b; tab-size: 4;">log</span>(<span class="hljs-built_in" style="box-sizing: border-box; color: #e6c07b; tab-size: 4;">i</span>);
  }
}</pre></div>
  `,
  }}
  />
);

const Github = () => (
  <div dangerouslySetInnerHTML={{
    __html: `
    <div class="hljs code-wrapper" style="box-sizing: border-box; position: relative; font-family: Consolas,'Liberation Mono',Menlo,Courier,monospace; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial; text-decoration-color: initial; display: block; overflow-x: auto; padding: 0.5em; color: #333; background: #f8f8f8; white-space: pre-wrap; word-break: break-all; word-wrap: break-word; tab-size: 4;"><pre class="hljs pre-wrapper" style="border-radius: 3px; box-sizing: border-box; font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace; font-size: 13.6px; margin-bottom: 0px; margin-top: 0px; overflow: auto; position: relative; word-break: normal; word-wrap: normal; display: block; overflow-x: auto; padding: 0.5em; color: #333; background: #f8f8f8; tab-size: 4; font-size: 14px;"><span class="hljs-function" style="box-sizing: border-box; tab-size: 4;"><span class="hljs-keyword" style="box-sizing: border-box; color: #333; font-weight: bold; tab-size: 4;">function</span> <span class="hljs-title" style="box-sizing: border-box; color: #900; font-weight: bold; tab-size: 4;">ex</span><span class="hljs-params" style="box-sizing: border-box; tab-size: 4;">(i)</span> {</span>
  <span class="hljs-keyword" style="box-sizing: border-box; color: #333; font-weight: bold; tab-size: 4;">if</span> (<span class="hljs-built_in" style="box-sizing: border-box; color: #0086b3; tab-size: 4;">i</span> &lt; <span class="hljs-number" style="box-sizing: border-box; color: #008080; tab-size: 4;">2</span>) {
    console.<span class="hljs-built_in" style="box-sizing: border-box; color: #0086b3; tab-size: 4;">log</span>(<span class="hljs-built_in" style="box-sizing: border-box; color: #0086b3; tab-size: 4;">i</span>);
  }
}</pre></div>
  `,
  }}
  />
);

const GithubGist = () => (
  <div dangerouslySetInnerHTML={{
    __html: `
    <div class="hljs code-wrapper" style="box-sizing: border-box; position: relative; font-family: Consolas,'Liberation Mono',Menlo,Courier,monospace; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial; text-decoration-color: initial; display: block; background: white; padding: 0.5em; color: #333333; overflow-x: auto; white-space: pre-wrap; word-break: break-all; word-wrap: break-word; tab-size: 4;"><pre class="hljs pre-wrapper" style="border-radius: 3px; box-sizing: border-box; font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace; font-size: 13.6px; margin-bottom: 0px; margin-top: 0px; overflow: auto; position: relative; word-break: normal; word-wrap: normal; display: block; background: white; padding: 0.5em; color: #333333; overflow-x: auto; tab-size: 4; font-size: 14px;"><span class="hljs-function" style="box-sizing: border-box; tab-size: 4;"><span class="hljs-keyword" style="box-sizing: border-box; color: #a71d5d; tab-size: 4;">function</span> <span class="hljs-title" style="box-sizing: border-box; color: #795da3; tab-size: 4;">ex</span><span class="hljs-params" style="box-sizing: border-box; tab-size: 4;">(i)</span> {</span>
  <span class="hljs-keyword" style="box-sizing: border-box; color: #a71d5d; tab-size: 4;">if</span> (<span class="hljs-built_in" style="box-sizing: border-box; tab-size: 4;">i</span> &lt; <span class="hljs-number" style="box-sizing: border-box; tab-size: 4;">2</span>) {
    console.<span class="hljs-built_in" style="box-sizing: border-box; tab-size: 4;">log</span>(<span class="hljs-built_in" style="box-sizing: border-box; tab-size: 4;">i</span>);
  }
}</pre></div>
  `,
  }}
  />
);

const Dark = () => (
  <div dangerouslySetInnerHTML={{
    __html: `
    <div class="hljs code-wrapper" style="box-sizing: border-box; position: relative; font-family: Consolas,'Liberation Mono',Menlo,Courier,monospace; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial; text-decoration-color: initial; display: block; overflow-x: auto; padding: 0.5em; background: #444; color: #ddd; white-space: pre-wrap; word-break: break-all; word-wrap: break-word; tab-size: 4;"><pre class="hljs pre-wrapper" style="border-radius: 3px; box-sizing: border-box; font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace; font-size: 13.6px; margin-bottom: 0px; margin-top: 0px; overflow: auto; position: relative; word-break: normal; word-wrap: normal; display: block; overflow-x: auto; padding: 0.5em; background: #444; color: #ddd; tab-size: 4; font-size: 14px;"><span class="hljs-function" style="box-sizing: border-box; tab-size: 4;"><span class="hljs-keyword" style="box-sizing: border-box; color: white; font-weight: bold; tab-size: 4;">function</span> <span class="hljs-title" style="box-sizing: border-box; color: #d88; font-weight: bold; tab-size: 4;">ex</span><span class="hljs-params" style="box-sizing: border-box; tab-size: 4;">(i)</span> {</span>
  <span class="hljs-keyword" style="box-sizing: border-box; color: white; font-weight: bold; tab-size: 4;">if</span> (<span class="hljs-built_in" style="box-sizing: border-box; color: #d88; tab-size: 4;">i</span> &lt; <span class="hljs-number" style="box-sizing: border-box; tab-size: 4;">2</span>) {
    console.<span class="hljs-built_in" style="box-sizing: border-box; color: #d88; tab-size: 4;">log</span>(<span class="hljs-built_in" style="box-sizing: border-box; color: #d88; tab-size: 4;">i</span>);
  }
}</pre></div>
  `,
  }}
  />
);

const Grayscale = () => (
  <div dangerouslySetInnerHTML={{
    __html: `
    <div class="hljs code-wrapper" style="box-sizing: border-box; position: relative; font-family: Consolas,'Liberation Mono',Menlo,Courier,monospace; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial; text-decoration-color: initial; display: block; overflow-x: auto; padding: 0.5em; color: #333; background: #fff; white-space: pre-wrap; word-break: break-all; word-wrap: break-word; tab-size: 4;"><pre class="hljs pre-wrapper" style="border-radius: 3px; box-sizing: border-box; font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace; font-size: 13.6px; margin-bottom: 0px; margin-top: 0px; overflow: auto; position: relative; word-break: normal; word-wrap: normal; display: block; overflow-x: auto; padding: 0.5em; color: #333; background: #fff; tab-size: 4; font-size: 14px;"><span class="hljs-function" style="box-sizing: border-box; tab-size: 4;"><span class="hljs-keyword" style="box-sizing: border-box; color: #333; font-weight: bold; tab-size: 4;">function</span> <span class="hljs-title" style="box-sizing: border-box; color: #000; font-weight: bold; tab-size: 4;">ex</span><span class="hljs-params" style="box-sizing: border-box; tab-size: 4;">(i)</span> {</span>
  <span class="hljs-keyword" style="box-sizing: border-box; color: #333; font-weight: bold; tab-size: 4;">if</span> (<span class="hljs-built_in" style="box-sizing: border-box; color: #000; text-decoration: underline; tab-size: 4;">i</span> &lt; <span class="hljs-number" style="box-sizing: border-box; color: #777; tab-size: 4;">2</span>) {
    console.<span class="hljs-built_in" style="box-sizing: border-box; color: #000; text-decoration: underline; tab-size: 4;">log</span>(<span class="hljs-built_in" style="box-sizing: border-box; color: #000; text-decoration: underline; tab-size: 4;">i</span>);
  }
}</pre></div>
    `,
  }}
  />
);

const Purebasic = () => (
  <div dangerouslySetInnerHTML={{
    __html: `
    <div class="hljs code-wrapper" style="box-sizing: border-box; position: relative; font-family: Consolas,'Liberation Mono',Menlo,Courier,monospace; font-size: 16px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-style: initial; text-decoration-color: initial; display: block; overflow-x: auto; padding: 0.5em; background: #FFFFDF; color: #000000; white-space: pre-wrap; word-break: break-all; word-wrap: break-word; tab-size: 4;"><pre class="hljs pre-wrapper" style="border-radius: 3px; box-sizing: border-box; font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace; font-size: 13.6px; margin-bottom: 0px; margin-top: 0px; overflow: auto; position: relative; word-break: normal; word-wrap: normal; display: block; overflow-x: auto; padding: 0.5em; background: #FFFFDF; color: #000000; tab-size: 4; font-size: 14px;">
<span class="hljs-function" style="box-sizing: border-box; color: #000000; tab-size: 4;"><span class="hljs-keyword" style="box-sizing: border-box; color: #006666; font-weight: bold; tab-size: 4;">function</span> <span class="hljs-title" style="box-sizing: border-box; color: #006666; tab-size: 4;">ex</span>(<span class="hljs-params" style="box-sizing: border-box; color: #000000; tab-size: 4;">i</span>) </span>{
  <span class="hljs-keyword" style="box-sizing: border-box; color: #006666; font-weight: bold; tab-size: 4;">if</span> (i &lt; <span class="hljs-number" style="box-sizing: border-box; color: #000000; tab-size: 4;">2</span>) {
    <span class="hljs-built_in" style="box-sizing: border-box; color: #006666; font-weight: bold; tab-size: 4;">console</span>.log(i);
  }
}
</pre></div>
    `,
  }}
  />
);

export default {
  atomOneDark: AtomOneDark,
  github: Github,
  githubGist: GithubGist,
  dark: Dark,
  grayscale: Grayscale,
  purebasic: Purebasic,
};
