import React from 'react';
import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import MarkdownTable from './MarkdownTable';
import DownloadBadge from '../DownloadBadge';
import markdownThemes from '../../../../evertool/post-script/markdown-themes.json';

class MarkdownTheme extends React.PureComponent {
  state = {
    currentTheme: 'all',
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  updateDimensions = () => {
    const width = this.getWidth();
    if (width <= 769) {
      this.setState({ currentTheme: 'default' });
    }
  }

  getWidth = () => {
    const w = window;
    const d = document;
    const { documentElement } = d;
    const body = d.getElementsByTagName('body')[0];
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth;

    return width;
  }


  onChangeTheme = (e) => {
    this.setState({ currentTheme: e.target.value });
  }

  render() {
    const { currentTheme } = this.state;

    return (
      <div>
        <MediaQuery query="(max-device-width: 769px)">
          <div className="select">
            <select onChange={this.onChangeTheme}>
              {Object.keys(markdownThemes).map(theme => (
                <option key={theme} value={theme}>{theme}</option>
              ))}
            </select>
          </div>
        </MediaQuery>
        <MarkdownTable allThemes={currentTheme === 'all' ? markdownThemes : { [currentTheme]: markdownThemes[currentTheme] }} />
        <hr />
        <p className="mt3 mb3 is-size-4">
          <FormattedMessage id="markdownPreview.cta" />
        </p>
        <DownloadBadge />
      </div>
    );
  }
}

export default MarkdownTheme;
