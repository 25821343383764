import React from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import styles from './MarkdownTable.module.scss';
import { camelCased } from '../../utils';
import highlightCodes from './HighlightCode';

const Text = ({ style, children }) => (
  <span style={style}>{children}</span>
);

const MarkdownTable = ({ allThemes }) => {
  const specs = Object.values(allThemes);
  const themeNames = Object.keys(allThemes);

  return (
    <table className={cx('table is-fullwidth is-hoverable', styles.table)}>
      <thead>
        <tr>
          <th style={{ width: '150px' }} />
          {themeNames.map(name => <th key={name}>{name}</th>)}
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            #
            {' '}
            <FormattedMessage id="markdownPreview.head" />
          </td>
          {specs.map(spec => (
            <td><Text style={spec.h1}><FormattedMessage id="markdownPreview.head" /></Text></td>
          ))}
        </tr>
        <tr>
          <td>
            ##
            {' '}
            <FormattedMessage id="markdownPreview.head" />
          </td>
          {specs.map(spec => (
            <td><Text style={spec.h2}><FormattedMessage id="markdownPreview.head" /></Text></td>
          ))}
        </tr>
        <tr>
          <td>
            ###
            {' '}
            <FormattedMessage id="markdownPreview.head" />
          </td>
          {specs.map(spec => (
            <td><Text style={spec.h3}><FormattedMessage id="markdownPreview.head" /></Text></td>
          ))}
        </tr>
        <tr>
          <td>
            ####
            {' '}
            <FormattedMessage id="markdownPreview.head" />
          </td>
          {specs.map(spec => (
            <td><Text style={spec.h4}><FormattedMessage id="markdownPreview.head" /></Text></td>
          ))}
        </tr>
        <tr>
          <td>
            #####
            {' '}
            <FormattedMessage id="markdownPreview.head" />
          </td>
          {specs.map(spec => (
            <td><Text style={spec.h5}><FormattedMessage id="markdownPreview.head" /></Text></td>
          ))}
        </tr>
        <tr>
          <td>
            ######
            {' '}
            <FormattedMessage id="markdownPreview.head" />
          </td>
          {specs.map(spec => (
            <td><Text style={spec.h6}><FormattedMessage id="markdownPreview.head" /></Text></td>
          ))}
        </tr>
        <tr>
          <td><FormattedMessage id="markdownPreview.paragraph" /></td>
          {specs.map(spec => (
            <td><Text style={spec.p}><FormattedMessage id="markdownPreview.text" /></Text></td>
          ))}
        </tr>
        <tr>
          <td>
            **
            <FormattedMessage id="markdownPreview.bold" />
            **
          </td>
          {specs.map(spec => (
            <td><Text style={spec.strong}><FormattedMessage id="markdownPreview.text" /></Text></td>
          ))}
        </tr>
        <tr>
          <td>
            *
            <FormattedMessage id="markdownPreview.head" />
            *
          </td>
          {specs.map(spec => (
            <td><Text style={spec.em}><FormattedMessage id="markdownPreview.text" /></Text></td>
          ))}
        </tr>
        <tr>
          <td>
            [
            <FormattedMessage id="markdownPreview.link" />
            ](http://)
          </td>
          {specs.map(spec => (
            <td>
              <a style={spec.a}><FormattedMessage id="markdownPreview.text" /></a>
            </td>
          ))}
        </tr>
        <tr>
          <td>
            ==
            <FormattedMessage id="markdownPreview.mark" />
            ==
          </td>
          {specs.map(spec => (
            <td key={spec.mark}>
              <Text style={spec.mark}><FormattedMessage id="markdownPreview.text" /></Text>
            </td>
          ))}
        </tr>
        <tr>
          <td>
            &gt;
            {' '}
            <FormattedMessage id="markdownPreview.blockquote" />
          </td>
          {specs.map(spec => (
            <td key={spec.blockquote}>
              <Text style={spec.blockquote}><FormattedMessage id="markdownPreview.text" /></Text>
            </td>
          ))}
        </tr>
        <tr>
          <td>
            1.
            {' '}
            <FormattedMessage id="markdownPreview.ol" />
          </td>
          {specs.map(spec => (
            <td key={spec.ol}>
              <ol style={spec.ol}>
                <li><FormattedMessage id="markdownPreview.text" /></li>
                <li><FormattedMessage id="markdownPreview.text" /></li>
              </ol>
            </td>
          ))}
        </tr>
        <tr>
          <td>
            *
            {' '}
            <FormattedMessage id="markdownPreview.ul" />
          </td>
          {specs.map(spec => (
            <td key={spec.ul}>
              <ul style={spec.ul}>
                <li><FormattedMessage id="markdownPreview.text" /></li>
                <li><FormattedMessage id="markdownPreview.text" /></li>
              </ul>
            </td>
          ))}
        </tr>
        <tr>
          <td>
            ```
            <br />
            <FormattedMessage id="markdownPreview.code" />
            <br />
            ```
          </td>
          {specs.map((spec) => {
            const CodeTheme = highlightCodes[camelCased(spec.codeBlock)];
            return (
              <td key={spec.codeBlock}>
                <CodeTheme />
              </td>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
};

export default MarkdownTable;
