import React from 'react';
import { graphql } from 'gatsby';
import { IntlProvider, FormattedMessage } from 'react-intl';
import Layout from '../../components/layout';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Headline from '../../components/Headline';
import SubHeadline from '../../components/SubHeadline';
import MarkdownTheme from '../../components/MarkdownTheme';
import getMessages from '../../locales/getMessages';
import styles from './markdown-themes.module.scss';

const messages = getMessages();

const meta = {
  en: {
    title: 'Use multiple Markdown themes in Evernote - EverTool',
    subtitle: 'Customize your Evernote Markdown themes with multiple Markdown themes supported by EverTool',
  },
  'zh-tw': {
    title: '在 Evernote 使用各種 Markdown 主題 - EverTool',
    subtitle: 'EverTool 支援多種 Markdown 主題，讓你的 Evernote 筆記更好看',
  },
  'zh-cn': {
    title: '在印象笔记使用各种 Markdown 主题 - EverTool',
    subtitle: 'EverTool 支援多种 Markdown 主题，让你的印象笔记更好看',
  },
  ja: {
    title: 'Evernoteの中に複数のマークダウンのテーマを使用します - EverTool',
    subtitle: 'EverToolでサポートされている複数のMarkdownのテーマであなたのEvernote値下げのテーマをカスタマイズします',
  },
};

const MarkdownThemesRoute = ({ pageContext, data }) => {
  const { url } = data.site.siteMetadata;
  const { locale } = pageContext;

  return (
    <Layout
      pageContext={pageContext}
      seo={{
        title: meta[locale].title,
        subtitle: meta[locale].subtitle,
        hreflangs: pageContext.hreflangs,
        url,
        locale,
      }}
    >
      <IntlProvider locale={locale} messages={messages[locale]} textComponent={React.Fragment}>
        <div>
          <Header />
          <div className="how-to-wrapper center">
            <Headline>
              <FormattedMessage id="markdown.theme.title" />
            </Headline>
            <SubHeadline>
              <FormattedMessage id="markdown.theme.subtitle" />
            </SubHeadline>
            <div className={styles.wrapper}>
              <MarkdownTheme />
            </div>
          </div>
        </div>
        <Footer />
      </IntlProvider>
    </Layout>
  );
};

export default MarkdownThemesRoute;

export const pageQuery = graphql`
  query MarkdownThemeQuery {
    site {
      siteMetadata {
        url
      }
    }
  }
`;
