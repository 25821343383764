import React from 'react';
import cx from 'classnames';
import styles from './Headline.module.scss';

const Headline = ({ children }) => (
  <h1 className={cx('is-size-2 mb3', styles.padding)}>
    {children}
  </h1>
);

export default Headline;
